import AppImage from '@theme/components/atom/AppImage'
import AppLink from '@theme/components/atom/AppLink'
import AppTitle from '@theme/components/atom/AppTitle'
import HeadingLevel from '~/mixins/HeadingLevel'

export default {
  mixins: [HeadingLevel],
  components: {
    AppImage,
    AppLink,
    AppTitle,
  },
  props: {
    subCategory: {
      type: Object,
      required: true,
    },
    imageCrop: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.SubCategoryBox.image.crop
      },
    },
    imageHeight: {
      type: Number,
      default() {
        return this.$themeSettings.components.SubCategoryBox.image.height
      },
    },
    imageUpscale: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.SubCategoryBox.image.upscale
      },
    },
    imageWidth: {
      type: Number,
      default() {
        return this.$themeSettings.components.SubCategoryBox.image.width
      },
    },
    styleModifier: {
      type: String,
      default() {
        return this.$themeSettings.components.SubCategoryBox.styleModifier
      },
    },
  },
  data() {
    return {
      subCategoryBoxClasses: [],
    }
  },
  mounted() {
    if (this.styleModifier !== '') {
      const styleModifiers = this.styleModifier.split(' ')
      styleModifiers.forEach(styleModifier => {
        this.subCategoryBoxClasses.push(`SubCategoryBox--${styleModifier}`)
      })
    }
  },
}

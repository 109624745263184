import AppImage from '@theme/components/atom/AppImage'
import AppLink from '@theme/components/atom/AppLink'
import AppTitle from '@theme/components/atom/AppTitle'
import Container from '@theme/components/utils/Container'
import SubCategoryBox from '@theme/components/category/SubCategoryBox'

export default {
  components: {
    AppImage,
    AppLink,
    AppTitle,
    Container,
    Dropdown: () => import('@theme/components/utils/Dropdown'),
    LazyMainMenuDropdownContent: () => import('@theme/components/shop/MainMenuDropdownContent'),
    SubCategoryBox,
  },
  data() {
    return {
      dropdownsLoaded: false,
      innerContainerClass: 'container',
      layoutMenuOverlay: false,
      mainMenuLinkIcon: require('@icon/down-arrow.svg?raw'),
    }
  },
  beforeMount() {
    this.$nuxt.$on('layoutMenuOverlayShow', this.layoutMenuOverlayShow)
    this.$nuxt.$on('layoutMenuOverlayHide', this.layoutMenuOverlayHide)
  },
  beforeDestroy() {
    this.$nuxt.$off('layoutMenuOverlayShow', this.layoutMenuOverlayShow)
    this.$nuxt.$off('layoutMenuOverlayHide', this.layoutMenuOverlayHide)
  },
  computed: {
    activeMenuItems() {
      const activeMenuItems = []
      let item = this.$store.state.menu.activeMenuItem
      while (item) {
        activeMenuItems.push(item)
        item = item.parent
      }
      return activeMenuItems
    },
  },
  methods: {
    dropdownHide() {
      this.$nuxt.$emit('layoutBaseOverlayHide')
    },
    dropdownShow() {
      this.$nuxt.$emit('layoutBaseOverlayShow')
    },
    layoutMenuOverlayHide() {
      this.layoutMenuOverlay = false
    },
    layoutMenuOverlayShow() {
      this.layoutMenuOverlay = true
    },
    loadDropdowns() {
      if (!this.dropdownsLoaded) {
        this.dropdownsLoaded = true
      }
    },
  },
}

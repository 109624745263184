export default {
  props: {
    elementClass: {
      type: String,
      default: '',
    },
    fullViewportWidthWrapper: {
      type: Boolean,
      required: true,
    },
    innerContainerClass: {
      type: String,
      default: 'container',
    },
  },
}
